<template>
  <Browse
    :columns="[
      'customer',
      'date',
      'weight',
      'dress_size',
      'right_arm',
      'left_arm',
      'chest',
    ]"
    :formats="{
      date: 'Date',
      weight: 'Decimal',
      dress_size: 'Decimal',
      right_arm: 'Decimal',
      left_arm: 'Decimal',
      chest: 'Decimal',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="measurements"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
