var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'customer',
    'date',
    'weight',
    'dress_size',
    'right_arm',
    'left_arm',
    'chest' ],"formats":{
    date: 'Date',
    weight: 'Decimal',
    dress_size: 'Decimal',
    right_arm: 'Decimal',
    left_arm: 'Decimal',
    chest: 'Decimal',
  },"itemsPerPage":"10","searchOnStart":true,"table":"measurements"}})}
var staticRenderFns = []

export { render, staticRenderFns }